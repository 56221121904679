<template>
  <Form id="kt_modal_user_form" class="form" @submit="save">
    <!--begin::Input group-->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col-->
          <div class="col-6">
            <!--begin::Label-->
            <label
              class="d-flex align-items-center fs-6 fw-bold form-label mb-2"
            >
              <span class="required">{{ translate("name") }}</span>
              <i
                class="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="Ingresa tu nombre"
              ></i>
            </label>
            <!--end::Label-->

            <Field
              v-model="userForm.name"
              type="text"
              class="form-control form-control-solid"
              placeholder="Ingresa tu nombre"
              name="name"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="name" />
              </div>
            </div>
          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-6">
            <!--begin::Label-->
            <label class="required fs-6 fw-bold form-label mb-2">{{
              translate("lastName")
            }}</label>
            <!--end::Label-->

            <!--begin::Input wrapper-->
            <div class="position-relative">
              <!--begin::Input-->
              <Field
                v-model="userForm.lastName"
                type="text"
                class="form-control form-control-solid"
                placeholder="Apellido(s)"
                name="lastName"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="lastName" />
                </div>
              </div>
              <!--end::Input-->
            </div>
            <!--end::Input wrapper-->
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col-->
          <div class="col-4">
            <!--begin::Label-->
            <label class="required fs-6 fw-bold form-label mb-2">{{
              translate("cellphone")
            }}</label>
            <!--end::Label-->
            <Field
              type="cellphone"
              v-model="userForm.cellphone"
              class="form-control form-control-solid"
              placeholder="Celular"
              name="cellphone"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="cellphone" />
              </div>
            </div>
          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-8">
            <!--begin::Label-->
            <label class="required fs-6 fw-bold form-label mb-2"
              >Correo electrónico
            </label>
            <!--end::Label-->
            <Field
              type="email"
              v-model="userForm.email"
              class="form-control form-control-solid"
              placeholder="Correo electrónico"
              name="email"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="email" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Actions-->
    <div class="text-center mt-3">
      <button
        ref="cancelButtonRef"
        type="button"
        id="cancel_button"
        class="btn btn-light me-3"
        @click="$emit('onCancel')"
      >
        <span class="indicator-label">
          Cancelar
        </span>
      </button>

      <button
        ref="submitButtonRef"
        type="submit"
        id="kt_modal_new_card_submit"
        class="btn btn-primary"
      >
        <span class="indicator-label">
          Guardar
        </span>
        <span class="indicator-progress">
          Please wait...
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </div>
    <!--end::Actions-->
  </Form>
</template>

<script>
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";

const EMPTY_USER = {
  id: null,
  name: "",
  lastName: "",
  email: "",
  cellphone: ""
};

export default defineComponent({
  components: {
    ErrorMessage,
    Field,
    Form
  },
  props: {
    user: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      userForm: { ...EMPTY_USER }
    };
  },
  watch: {
    user(userValue) {
      this.userForm = userValue ? userValue : { ...EMPTY_USER };
      if (userValue && userValue.lastname) {
        this.userForm.lastName = userValue.lastname;
      }
      if (userValue && userValue.email) {
        this.userForm.email = userValue.email;
      }
      if (userValue && userValue.id) {
        this.userForm.id = userValue.id;
      }
    }
  },
  methods: {
    save() {
      this.$emit("onSave", {
        ...this.userForm,
        user: this.userForm.id,
        lastname: this.userForm.lastName
      });
    }
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate
    };
  }
});
</script>
