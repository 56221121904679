<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">{{ translate("accountData") }}</h3>
      </div>
      <!--end::Card title-->

      <!--begin::Action-->
      <button
        v-if="!isUserFormVisible"
        class="btn btn-primary align-self-center"
        @click="startUserEdition"
      >
        {{ translate("edit") }}
      </button>
      <!--end::Action-->
    </div>
    <!--begin::Card header-->

    <!--begin::Card body-->
    <div class="card-body p-9">
      <!-- begin:: User information -->
      <div v-if="!isUserFormVisible" class="user-information-holder mb-9">
        <!--begin::Row-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted">{{
            translate("name")
          }}</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">
              {{ authenticatedUser.name }}
            </span>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->

        <!--begin::Row-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted">{{
            translate("lastName")
          }}</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{
              authenticatedUser.lastname
            }}</span>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->

        <!--begin::Input group-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted">{{
            translate("email")
          }}</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8">
            <a href="#" class="fw-bold fs-6 text-dark text-hover-primary">{{
              authenticatedUser.email
            }}</a>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->

        <!--begin::Input group-->
        <div class="row">
          <!--begin::Label-->
          <label
            class="col-lg-4 fw-bold text-muted"
            style="text-transform: capitalize"
            >{{ translate("cellphone") }}</label
          >
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8">
            <a href="#" class="fw-bold fs-6 text-dark text-hover-primary">{{
              authenticatedUser.cellphone
            }}</a>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->
      </div>
      <!-- end:: User information -->

      <!-- begin:: User form -->
      <div v-else class="user-form-holder mb-9">
        <UserForm
          :user="accountUser"
          @onSave="saveUserData"
          @onCancel="endUserEdition()"
        />
      </div>
      <!-- end:: User form -->

      <div
        v-if="!authenticatedUser.validated_email"
        class="
          notice
          d-flex
          bg-light-warning
          rounded
          border-warning border border-dashed
          p-6
        "
      >
        <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
          <inline-svg src="media/icons/duotone/Code/Warning-1-circle.svg" />
        </span>
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack flex-grow-1">
          <!--begin::Content-->
          <div class="fw-bold">
            <h4 class="text-gray-800 fw-bolder">
              {{ translate("important") }}
            </h4>

            <div class="fs-6 text-gray-600">
              {{ first() }}
            </div>
            <div class="fs-6 text-gray-600">
              {{ second()[0] }}
              <a href="#" @click.prevent="resendEmail">{{
                translate("here")
              }}</a>
              {{ second()[1] }}
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Wrapper-->
      </div>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::details View-->
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import UserForm from "@/components/app/Account/UserForm.vue";
import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "account-overview",
  components: {
    UserForm
  },
  data() {
    return {
      isUserFormVisible: false,
      isWaiting: false,
      waitingMessage: "",
      accountUser: null,
      authenticatedUser: this.$store.getters.currentUser
    };
  },

  methods: {
    startUserEdition() {
      this.isUserFormVisible = true;
      setTimeout(() => {
        this.accountUser = { ...this.authenticatedUser };
      }, 333);
    },
    endUserEdition() {
      this.isUserFormVisible = false;
      setTimeout(() => {
        this.accountUser = null;
      }, 333);
    },
    saveUserData(userData) {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/users/update-data`;
      const currentToken = JwtService.getToken();

      this.isWaiting = true;
      this.waitingMessage = "Guardando...";

      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(userData)
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            throw new Error("authorizationError");
          } else if (res.status !== 200 && res.status !== 201) {
            throw new Error("generalError");
          }

          return res.json();
        })
        .then(res => {
          this.isUserFormVisible = false;
          this.waitingMessage = "";
          this.authenticatedUser = {
            id: res.result.id,
            name: res.result.name,
            lastname: res.result.lastname,
            email: res.result.email,
            cellphone: res.result.cellphone
          };

          this.$notify({
            title: "Excelente",
            message: "Los datos del usuario se han guardado exitosamente",
            type: "success"
          });
        })
        .catch(error => {
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("alreadyInUse")
          });
        })
        .finally(() => {
          this.isWaiting = false;
        });
    }
  },

  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const resendEmail = () => {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/users/resend-email`;
      const currentToken = JwtService.getToken();

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => res.json())
        .then(() => {
          Swal.fire({
            text: translate("forwardedMail"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary"
            }
          });
        });
    };

    const first = () => {
      return translate("emailWarning.first");
    };

    const second = () => {
      const msg = translate("emailWarning.second").split("click-here");

      return msg;
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Datos de usuario", ["Configuración"]);
    });

    return {
      translate,
      resendEmail,
      first,
      second
    };
  }
});
</script>
